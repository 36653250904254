<template>
    <div class="info-box">
      <div class="info-box-content">
        <h2>{{ title }}</h2>
        <!-- Si c'est un tableau, on affiche chaque message -->
        <div v-if="Array.isArray(message)" class="message-container">
          <p v-for="(msg, index) in message" 
             :key="index" 
             class="message-item"
             v-html="formatMessage(msg)">
          </p>
        </div>
        <!-- Si c'est une string simple -->
        <p v-else v-html="formatMessage(message)"></p>
        <button @click="closeBox">
          Fermer
        </button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'InfoBox',
    
    props: {
      title: {
        type: String,
        default: 'Action requise'
      },
      message: {
        type: [String, Array],
        default: ''
      }
    },
  
    emits: ['close'],
  
    methods: {
      closeBox() {
        console.log('emit close')
        this.$emit('close');
      },
      
      formatMessage(msg) {
        if (!msg) return '';
        // Remplace le texte entre ** par du texte en gras
        return msg.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
      }
    }
  };
  </script>
  
  <style scoped>
  .info-box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  .info-box-content {
    background-color: white;
    color: var(--text-color);
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  .message-container {
    margin: 10px 0;
  }
  
  .message-item {
    margin: 8px 0;
  }
  
  .message-item:not(:last-child) {
    padding-bottom: 8px;
    border-bottom: 1px solid #eee;
  }
  
  button {
    padding: 10px 20px;
    background-color: #4fdab5;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #3cb99a;
  }
  </style>