
<template>
<div>
    <HeaderComponent />
    <section>
      <div class="p-4">
        <div v-if="loading" class="text-center">
          <p>Loading dashboard data...</p>
        </div>
        
        <div v-else-if="error" class="text-red-500 text-center">
          {{ error }}
        </div>
        
        <div v-else class="dashboard-content">
          <!-- API Calls Chart - Full width -->
          <div class="dashboard-card full-width">
            <h2 class="text-xl font-bold mb-4">API Calls Over Time</h2>
            <APICallsChart :chart-data="chartData" />
          </div>

          <!-- Two column layout container -->
          <div class="two-columns">
            <!-- IP Usage Table -->
            <div class="dashboard-card">
              <h2 class="text-xl font-bold mb-4">IP Usage</h2>
              <table class="min-w-full">
                <thead>
                  <tr>
                    <th class="text-left p-2">IP Address</th>
                    <th class="text-left p-2">Call Count</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="ip in ipUsageData" :key="ip.ip" class="border-t">
                    <td class="p-2">{{ ip.ip }}</td>
                    <td class="p-2">{{ ip.calls }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Active API Keys -->
            <div class="dashboard-card">
              <h2 class="text-xl font-bold mb-4">Active API Keys</h2>
              <table class="min-w-full">
                <thead>
                  <tr>
                    <th class="text-left p-2">Name</th>
                    <th class="text-left p-2">Created</th>
                    <th class="text-left p-2">Last Used</th>
                    <th class="text-left p-2">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="key in activeKeysData" :key="key.id" class="border-t">
                    <td class="p-2">{{ key.name }}</td>
                    <td class="p-2">{{ new Date(key.created).toLocaleDateString() }}</td>
                    <td class="p-2">{{ new Date(key.lastUsed).toLocaleDateString() }}</td>
                    <td class="p-2">
                      <span :class="key.status === 'active' ? 'text-green-500' : 'text-red-500'">
                        {{ key.status }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Recent Logs -->
            <div class="dashboard-card">
              <h2 class="text-xl font-bold mb-4">Recent API Calls</h2>
              <table class="min-w-full">
                <thead>
                  <tr>
                    <th class="text-left p-2">Time</th>
                    <th class="text-left p-2">Endpoint</th>
                    <th class="text-left p-2">Method</th>
                    <th class="text-left p-2">Status</th>
                    <th class="text-left p-2">IP</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="log in recentLogsData" :key="log.id" class="border-t">
                    <td class="p-2">{{ new Date(log.timestamp).toLocaleTimeString() }}</td>
                    <td class="p-2">{{ log.endpoint }}</td>
                    <td class="p-2">
                      <span :class="{
                        'text-blue-500': log.method === 'GET',
                        'text-green-500': log.method === 'POST',
                        'text-yellow-500': log.method === 'PUT',
                        'text-red-500': log.method === 'DELETE'
                      }">
                        {{ log.method }}
                      </span>
                    </td>
                    <td class="p-2">
                      <span :class="{
                        'text-green-500': log.status < 300,
                        'text-yellow-500': log.status >= 300 && log.status < 400,
                        'text-red-500': log.status >= 400
                      }">
                        {{ log.status }}
                      </span>
                    </td>
                    <td class="p-2">{{ log.ip }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <InfoBox 
        v-if="showInfoBox" 
        :title="infoBoxTitle" 
        :message="infoBoxMessage" 
        @close="handleCloseBox" 
      />
    
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { APIMetricsService } from '../services/APIMetricsService'
import APICallsChart from './APICallsChart.vue'
import HeaderComponent from './HeaderComponent.vue';
import { userService } from '../services/UserService'; // Import du service
import InfoBox from './InfoBox.vue'; // Import du composant InfoBox
export default {
  name: 'APIDashboard',
  
  components: {
    APICallsChart,
    HeaderComponent,
    InfoBox
  },
  data() {
    return {
      email: '',
      password: '',
      showPassword: false,
      showInfoBox:false,
    };
  },
  setup() {
    const apiCallsData = ref([])
    const ipUsageData = ref([])
    const activeKeysData = ref([])
    const recentLogsData = ref([])
    const loading = ref(true)
    const error = ref(null)

    const chartData = computed(() => {
      if (!apiCallsData.value || apiCallsData.value.length === 0) {
        return {
          labels: [],
          datasets: [{
            label: 'API Calls',
            data: [],
            backgroundColor: 'rgba(79, 218, 181, 0.2)',
            borderColor: '#4fdab5',
            borderWidth: 2,
            tension: 0.4
          }]
        }
      }

      return {
        labels: apiCallsData.value.map(d => d.time),
        datasets: [{
          label: 'API Calls',
          data: apiCallsData.value.map(d => d.calls),
          backgroundColor: 'rgba(79, 218, 181, 0.2)',
          borderColor: '#4fdab5',
          borderWidth: 2,
          tension: 0.4
        }]
      }
    })

    const fetchData = async () => {
      try {
        const [calls, ips, keys, logs] = await Promise.all([
          APIMetricsService.getAPICallsOverTime(),
          APIMetricsService.getIPUsage(),
          APIMetricsService.getActiveAPIKeys(),
          APIMetricsService.getRecentLogs()
        ])

        apiCallsData.value = calls.timestamps.map((time, i) => ({
          time,
          calls: calls.counts[i]
        }))
        ipUsageData.value = ips
        activeKeysData.value = keys
        recentLogsData.value = logs
      } catch (e) {
        error.value = 'Error loading dashboard data'
        console.error(e)
      } finally {
        loading.value = false
      }
    }

    onMounted(() => {
      fetchData()
      const interval = setInterval(fetchData, 30000)
      userService.whois()
      return () => clearInterval(interval)
    })

    return {
      chartData,
      ipUsageData,
      activeKeysData,
      recentLogsData,
      loading,
      error
    }
  }
}
</script>

<style scoped>
canvas {
  width: 100% !important;
  height: 300px !important;
}
section {
  margin-top: 50px;
  text-align: center;
  background-color: var(--background-color);
  color: var(--text-color);
}
input, button {
  padding: 10px;
  margin: 10px;
  font-size: 16px;
}
button {
  background-color: var(--button-color);
  border: none;
  top: 50%;
} 


.grid {
  text-align: left;
}

.space-y-6 > * + * {
  margin-top: 1.5rem;
}
@media (max-width: 768px) {
  .grid > div {
    min-width: 100%;
  }
}


.dashboard-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 1400px;
  margin: 0 auto;
}

.dashboard-card {
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.full-width {
  width: 100%;
}

.two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

/* Responsive design */
@media (max-width: 1024px) {
  .two-columns {
    grid-template-columns: 1fr;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 0.5rem;
  text-align: left;
}

tbody tr:hover {
  background-color: #f9f9f9;
}
</style>