<template>
  <div>
    <HeaderComponent />
      <section>
      <div class="max-w-4xl mx-auto items-center p-6">

        <div class="flex justify-between items-center mb-6">
          <h1 class="text-2xl font-bold">API Key Management</h1>
          <button 
            @click="showAddForm = !showAddForm"
            class="action-button"
          >
            {{ showAddForm ? 'Cancel' : 'Add New API Key' }}
          </button>
        </div>

        <InfoBox 
          v-if="error" 
          title="Error"
          :message="error"
          @close="error = null"
        />

       
        <div v-if="loading" class="text-center py-4">Loading...</div>
        
        <div v-else-if="apis.length === 0" class="text-center py-4 text-gray-500">
          No API keys found
        </div>

        <div v-else>
          <DataTable 
            :headers="tableHeaders"
            :data="apis"
            @delete="deleteApi"
          />
        </div>
        <ApiFormModal
          v-if="showAddForm"
          :loading="loading"
          @submit="handleAddApi"
          @close="showAddForm = false"
        />
        <InfoBox 
          v-if="successMessage" 
          title="Success"
          :message="successMessage"
          @close="onClose()"
        />
      </div>
    </section>
  </div>
    
</template>

<script>
import { ref, onMounted } from 'vue'
import { apiService } from '../services/ApiService'
import InfoBox from './InfoBox.vue'
import DataTable from './DataTable.vue'
import HeaderComponent from './HeaderComponent.vue'
import ApiFormModal from './ApiFormModal.vue'
export default {
  name: 'ApiManagement',
  
  components: {
    InfoBox,
    DataTable,
    HeaderComponent,
    ApiFormModal
  },

  setup() {
    const apis = ref([])
    const loading = ref(false)
    const error = ref(null)
    const successMessage = ref(null)
    const showAddForm = ref(false)
    const newApi = ref({
      name: '',
      usage: '',
      expiration: '3 years'
    })

    const tableHeaders = [
      { key: 'name', label: 'Name' },
      { key: 'usage', label: 'Usage' },
      { key: 'createdAt', label: 'Created', format: 'date' },
      { key: 'expiresAt', label: 'Expires', format: 'date' }
    ]
    const handleAddApi = async (formData) => {
      try {
      newApi.value.name= formData.name
        newApi.value.usage= formData.usage
        addApi()
      } catch (err) {
        successMessage.value = {
          title: 'Error',
          content: err.message || 'Failed to create API key'
        }
      } finally {
        loading.value = false
      }
    }

    const fetchApis = async () => {
      try {
        loading.value = true
        
        const response = await apiService.getApis()
        if (response.success) {
          apis.value = response.data
        } else {
          throw new Error(response.message || 'Failed to fetch APIs')
        }
      } catch (err) {
        error.value = err.message
      } finally {
        loading.value = false
      }
    }

    const deleteApi = async (apiId) => {
      try {
        loading.value = true
        const response = await apiService.deleteApi(apiId)
        if (response.success) {
          successMessage.value = 'API key successfully deleted'
          fetchApis()
        } else {
          throw new Error(response.message || 'Failed to delete API')
        }
      } catch (err) {
        error.value = err.message
      } finally {
        loading.value = false
      }
    }

    const addApi = async () => {
      try {
        loading.value = true
        const response = await apiService.createApi(newApi.value)
        if (response.success) {
          showAddForm.value = false
          console.log('response',response)
          successMessage.value = [`New API key successfully created`,
          `**your secret**`,`**${response.secureKey}**`,
          `Save your secret, because is neccessary for each exchange call`,
          ]
          fetchApis()
        } else {
          throw new Error(response.message || 'Failed to add API')
        }
      } catch (err) {
        error.value = err.message
      } finally {
        loading.value = false
      }
    }
    const onClose = async ()=>{
      successMessage.value = null
      fetchApis()
    }
    onMounted(fetchApis)

    return {
      apis,
      loading,
      error,
      successMessage,
      showAddForm,
      newApi,
      tableHeaders,
      deleteApi,
      addApi,
      onClose,
      handleAddApi
    }
  }
}
</script>


<style scoped>


section {
  margin-top: 50px;
  text-align: center;
  background-color: var(--background-color);
  color: var(--text-color);
}
.action-button {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.action-button:hover {
  background-color: var(--button-color);
}

.action-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.delete-button {
  padding: 6px 12px;
  background-color: #ef4444;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #dc2626;
}

.space-y-4 > * + * {
  margin-top: 1rem;
}


.action-button {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.action-button:hover {
  background-color: var(--button-color);
}

.action-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

</style>