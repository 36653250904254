// APIMetricsService.js
export const APIMetricsService = {
  async getAPICallsOverTime() {
    // Mock service pour simuler les données d'appels API sur le temps
    return {
      timestamps: Array.from({ length: 24 }, (_, i) => `${i}:00`),
      counts: Array.from({ length: 24 }, () => 0)
    }
  },

  async getIPUsage() {
    // Mock service pour simuler l'utilisation par IP
    const ips = [
      
    ]
    return ips.map(ip => ({
      ip,
      calls: Math.floor(Math.random() * 1000)
    }))
  },

  async getActiveAPIKeys() {
    // Mock service pour simuler les clés API actives
    return Array.from({ length: 0 }, (_, i) => ({
      id: `key_${i}`,
      name: `API Key ${i + 1}`,
      created: new Date(Date.now() - Math.random() * 10000000000).toISOString(),
      lastUsed: new Date(Date.now() - Math.random() * 1000000).toISOString(),
      status: Math.random() > 0.2 ? 'active' : 'inactive'
    }))
  },

  async getRecentLogs() {
    // Mock service pour simuler les logs d'appels récents
    return Array.from({ length: 0 }, (_, i) => ({
      id: `log_${i}`,
      timestamp: new Date(Date.now() - i * 60000).toISOString(),
      endpoint: `/api/${['users', 'products', 'orders'][Math.floor(Math.random() * 3)]}`,
      method: ['GET', 'POST', 'PUT', 'DELETE'][Math.floor(Math.random() * 4)],
      status: [200, 201, 400, 401, 403, 500][Math.floor(Math.random() * 6)],
      ip: `192.168.${Math.floor(Math.random() * 255)}.${Math.floor(Math.random() * 255)}`
    }))
  }
}