// AuthService.js
import { reactive } from 'vue';

// Créer un état réactif pour l'authentification
const state = reactive({
    isAuthenticated: false
});

export const authService = {
    setToken(token) {
        localStorage.setItem('token', token);
        state.isAuthenticated = true;
    },
    
    getToken() {
        return localStorage.getItem('token');
    },
    setLogin(login) {
        localStorage.setItem('login', login);
        state.isAuthenticated = true;
    },
    
    getLogin() {
        return localStorage.getItem('login');
    },
    removeToken() {
        localStorage.removeItem('token');
        state.isAuthenticated = false;
    },
    
    isAuthenticated() {
        const hasToken = !!this.getToken();
        state.isAuthenticated = hasToken;
        return hasToken;
    },

    // Ajouter un getter pour l'état
    getState() {
        return state;
    }
};
