<template>
    <div class="modal-box">
      <div class="modal-content">
        <h2 class="text-xl mb-4">Add New API Key</h2>
        <form @submit.prevent="handleSubmit" class="form-container">
          <div class="form-group">
            <label class="form-label">Name</label>
            <input 
              v-model="formData.name"
              type="text"
              class="form-input"
              required
            />
          </div>
          <div class="form-group">
            <label class="form-label">Usage</label>
            <select 
              v-model="formData.usage"
              class="form-input"
              required
            >
              <option 
                v-for="option in exchangeOptions" 
                :key="option.value" 
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
          <div class="button-group">
            <button type="submit" class="submit-button" :disabled="loading">
              {{ loading ? 'Adding...' : 'Add API Key' }}
            </button>
            <button type="button" class="cancel-button" @click="$emit('close')">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue'
  
  export default {
    name: 'ApiFormModal',
    
    props: {
      loading: {
        type: Boolean,
        default: false
      }
    },
  
    emits: ['submit', 'close'],
  
    setup(props, { emit }) {
      const exchangeOptions = [
        { value: '.*', label: 'Tous les routes' },
        { value: '/ex/.*', label: 'Tous les exchanges' },
        { value: '/ex/kucoin/.*', label: 'KuCoin' },
        { value: '/ex/binance/.*', label: 'Binance' },
        { value: '/ex/coinbase/.*', label: 'Coinbase' },
        { value: '/ex/kraken/.*', label: 'Kraken' },
        { value: '/ex/ftx/.*', label: 'FTX' },
        { value: '/ex/huobi/.*', label: 'Huobi' },
        { value: '/ex/bybit/.*', label: 'Bybit' },
        { value: '/ex/bitfinex/.*', label: 'Bitfinex' },
        { value: '/ex/okx/.*', label: 'OKX' },
        { value: '/ex/gemini/.*', label: 'Gemini' },
        { value: '/ex/bitstamp/.*', label: 'Bitstamp' },
        { value: '/ex/gate/.*', label: 'Gate.io' },
        { value: '/ex/mexc/.*', label: 'MEXC' },
        { value: '/ex/cryptocom/.*', label: 'Crypto.com' }
      ]
  
      const formData = ref({
        name: '',
        usage: exchangeOptions[0].value,
        expiration: '3 years'
      })
  
      const handleSubmit = () => {
        emit('submit', { ...formData.value })
      }
  
      return {
        formData,
        handleSubmit,
        exchangeOptions
      }
    }
  }
  </script>
  
  <style scoped>
  .modal-box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  .modal-content {
    background-color: white;
    color: var(--text-color);
    padding: 20px;
    border-radius: 8px;
    text-align: left;
    max-width: 400px;
    width: 100%;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .form-label {
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .form-input {
    padding: 8px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    width: 100%;
    background-color: white;
  }
  
  select.form-input {
    cursor: pointer;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 16px;
    padding-right: 32px;
  }
  
  .button-group {
    display: flex;
    gap: 8px;
    margin-top: 8px;
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #4fdab5;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    flex: 1;
  }
  
  .submit-button:hover {
    background-color: #3cb99a;
  }
  
  .submit-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .cancel-button {
    padding: 10px 20px;
    background-color: #e2e8f0;
    color: #64748b;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cancel-button:hover {
    background-color: #cbd5e1;
  }
  </style>