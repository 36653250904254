import axios from 'axios';
import { authService } from './AuthService';

const API_URL = `${process.env.VUE_APP_API_URL}/users`;
const API_URL_LOGIN = `${process.env.VUE_APP_API_URL}/users/login`;
const API_URL_TOKEN = `${process.env.VUE_APP_API_URL}/users/validation`;
const API_URL_DOWNLOAD = `${process.env.VUE_APP_API_URL}/users/downloads/track`;
console.log("API_URL from environment:", API_URL); // Affiche la valeur dans la console

// Créer une instance axios avec une configuration par défaut
const axiosInstance = axios.create({
    headers: {
        'Content-Type': 'application/json'
    }
});

// Intercepteur pour ajouter le token à chaque requête
axiosInstance.interceptors.request.use(config => {
    const token = authService.getToken();
    console.log('token',token)
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});


export const userService = {
  whois(){

  },
  signup(userData) {

    return axios.post(API_URL, userData, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .catch(error => {
      console.error("There was an error with the signup:", error);
      throw error;
    });
  },
  login(userData) {

    return axios.post(API_URL_LOGIN, userData, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      if (response.status === 200) {
        if (response.data.success && response.data.token) {
            authService.setToken(response.data.token);
            authService.setLogin(response.data.login);
        }
        return response.data;
      }
    })
    .catch(error => {
      console.error("There was an error with the signup:", error);
      throw error;
    });
  },
  validate(token) {

    return axios.get(`${API_URL_TOKEN}?token=${token}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .catch(error => {
      console.error("There was an error with the signup:", error);
      throw error;
    });
  },
  logout() {
    authService.removeToken();
  },
  async trackDownload(downloadData) {
    try {
      const token = authService.getToken();
      const login = authService.getLogin();
      const headers={
        'Content-Type': 'application/json'
      };
      if(token){
        headers['Authorization']=`Bearer ${token}`;
        headers['COIN_GUILD_USER']=`${login}`;
      }
      console.log('headers',headers)
      const response = await fetch(API_URL_DOWNLOAD, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(downloadData)
      });
      
      if (!response.ok) {
        throw new Error('Failed to track download');
      }
      const data= await response.json();
      if (data.error) {
        throw new Error(data.message);
      }

      return data;
    } catch (error) {
      console.error('Error tracking download:', error);
      throw error;
    }
  }
};
