import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/HomePage.vue';
import LoginPage from './components/LoginPage.vue';
import SignupPage from './components/SignupPage.vue';
import TokenApisPage from './components/TokenApisPage.vue';
import ValidatePage from './components/ValidatePage.vue';
import DashBoardPage from './components/DashBoardPage.vue';
import DownLoadPage from './components/DownLoadPage.vue';
import { userService } from './services/UserService'; 
const routes = [
  { path: '/', component: HomePage },
  { path: '/dashboard', component: DashBoardPage },
  { path: '/download', component: DownLoadPage },
  { path: '/login', component: LoginPage },
  { path: '/apis', component: TokenApisPage },
  { path: '/signup', component: SignupPage },
  { path: '/user/validate', name: 'validate', component: ValidatePage },
  { path: '/logout',
    name: 'Logout',
    beforeEnter: (to, from, next) => {
        userService.logout();
        next('/');
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});
// Ajout d'un hook global pour le débogage

export default router;
