<template>
  <div class="table-wrapper">
    <div class="table-container">
      <table class="data-table">
        <thead>
          <tr>
            <th v-for="header in headers" 
                :key="header.key" 
                class="table-header">
              {{ header.label }}
            </th>
            <th class="table-header">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in data" :key="row.id" class="table-row">
            <td v-for="header in headers" 
                :key="header.key" 
                class="table-cell">
              {{ formatValue(row[header.key], header.format) }}
            </td>
            <td class="table-cell">
              <slot name="actions" :row="row">
                <button 
                  @click="$emit('delete', row.id)"
                  class="delete-button">
                  Delete
                </button>
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataTable',
  
  props: {
    headers: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true
    },
    maxWidth: {
      type: String,
      default: '800px'
    }
  },

  emits: ['delete'],

  methods: {
    formatValue(value, format) {
      if (!format) return value;
      
      switch (format) {
        case 'date':
          return new Date(value).toLocaleDateString();
        default:
          return value;
      }
    }
  }
}
</script>

<style scoped>
.table-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.table-container {
  width: 100%;
  max-width: v-bind(maxWidth);
  margin: 0 auto;
  overflow-x: auto;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  background: white;
}

.table-header {
  padding: 1rem;
  background-color: #f8f9fa;
  color: #666;
  font-weight: 600;
  border-bottom: 2px solid #e2e8f0;
}

.table-row {
  transition: background-color 0.2s ease;
}

.table-row:hover {
  background-color: #f8f9fa;
}

.table-cell {
  padding: 1rem;
  border-bottom: 1px solid #e2e8f0;
}

.delete-button {
  padding: 6px 12px;
  background-color: #ef4444;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #dc2626;
}
</style>