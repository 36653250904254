<template> 
  <nav class="menu-bar">
    <ul>
      <!-- Menu statique toujours visible -->
      <li><a href="#features">Features</a></li>
      <li><a href="#features">Security</a></li>
      <li><a href="#about">About</a></li>
      
      <li><a >|</a></li>
      
      <!-- Menu dynamique pour utilisateurs connectés -->
      <template v-if="isAuthenticated">
        <li>
          <router-link to="/Dashboard">
            Dashboard
          </router-link>
        </li>
        <li>
          <router-link to="/Download">
            Download
          </router-link>
        </li>
        <li>
          <router-link to="/apis">
            APIs
          </router-link>
        </li>
        <li>
          <a href="#" @click.prevent="handleLogout">
            Logout
          </a>
        </li>
      </template>

      <!-- Menu pour utilisateurs non connectés -->
      <template v-else>
        <li>
          <router-link to="/signup">
            Sign-up
          </router-link>
        </li>
        <li>
          <router-link to="/login">
            Log in
          </router-link>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script>
import { authService } from '../services/AuthService';
import { userService } from '../services/UserService';
import { ref, onMounted } from 'vue';

export default {
  name: 'MenuBar',
  setup() {
    const isAuthenticated = ref(false);

    // Vérifier l'état d'authentification au montage du composant
    onMounted(() => {
      checkAuthStatus();
    });

    // Fonction pour vérifier l'état d'authentification
    const checkAuthStatus = () => {
      isAuthenticated.value = authService.isAuthenticated();
    };

    // Gérer la déconnexion
    const handleLogout = () => {
      userService.logout();
      isAuthenticated.value = false;
      // Rediriger vers la page d'accueil ou de connexion
      window.location.href = '/';
    };

    return {
      isAuthenticated,
      handleLogout
    };
  }
}
</script>

<style scoped>
/* General styles for the navigation bar */
.menu-bar {
  background-color: rgb(254, 254, 255); /* Color 1 */
  padding: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Styling the list of navigation items */
.menu-bar ul {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  padding: 0;
}

/* Styling the individual list items */
.menu-bar ul li {
  margin: 0 15px;
}

/* Links styling */
.menu-bar ul li a {
  color:  rgb(29, 29, 29) ;/*rgb(189, 134, 46); /* Color 2 */
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  transition: color 0.3s ease, transform 0.3s ease;
}

/* Hover effect for links */
.menu-bar ul li a:hover {
  color: rgb(100, 140, 169); /* Color 3 */
  text-decoration: underline;
  transform: scale(1.1); /* Slight scaling effect */
}

/* Responsive design */
@media (max-width: 768px) {
  .menu-bar ul {
    flex-direction: column;
  }
  .menu-bar ul li {
    margin: 10px 0;
  }
}
</style>
