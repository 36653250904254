<template>
  <div id="app">
    <router-view />
  </div>
</template>
  
  <script>
  export default {
    name: 'App',
  };
  </script>

<style>
:root {
  --primary-color: #73d890; /* ou #1f8b47 */
  --secondary-color: #60A3D9; /* ou #3D85C6 */
  --background-color: #F0F4F8; /* ou #F4F1EB */
  --text-color: #333333;
  --button-color: #1f8b47; /* vert utilisé sur les boutons */
}

body {
  /*background-color: var(--background-color);*/
  background-color: var(--background-color);
  color: #ffffff; /* Texte principal en blanc */
  /*color: var(--text-color);*/
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}


</style>