<template>
    <div class="chart-container">
      <LineChart
        v-if="chartData && chartData.labels"
        :data="chartData"
        :options="chartOptions"
      />
      <div v-else class="loading-placeholder">
        Loading chart data...
      </div>
    </div>
  </template>
  
  <script>
  import { Line as LineChart } from 'vue-chartjs'
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
  } from 'chart.js'
  
  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
  )
  
  export default {
    name: 'APICallsChart',
    components: { 
      LineChart
    },
    props: {
      chartData: {
        type: Object,
        required: true,
        default: () => ({
          labels: [],
          datasets: [{
            label: 'API Calls',
            data: [],
            backgroundColor: 'rgba(79, 218, 181, 0.2)',
            borderColor: '#4fdab5',
            borderWidth: 2,
            tension: 0.4
          }]
        })
      }
    },
    setup() {
      const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top'
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              color: 'rgba(0, 0, 0, 0.1)'
            }
          },
          x: {
            grid: {
              color: 'rgba(0, 0, 0, 0.1)'
            }
          }
        }
      }
  
      return { chartOptions }
    }
  }
  </script>
  
  <style scoped>
  .chart-container {
    width: 100%;
    height: 300px;
    position: relative;
  }
  
  .loading-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #f9f9f9;
    border-radius: 8px;
    color: #666;
  }
  
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
  </style>