<template>
  <div class="coin-guild">
    <HeaderComponent />
    <!-- Hero Section -->
    <section class="hero">
      <h1>Welcome to Coin Guild</h1>
      <p>Empowering you to manage your cryptocurrency investments effortlessly.</p>
      <a
        href="#get-started"
        class="btn-primary"
      >Get Started</a>
    </section>

    <!-- Features Section -->
    <section
      id="features"
      class="features"
    >
      <h2>Features</h2>
      <div class="features-list">
        <div class="feature-item">
          <h3>Portfolio Tracking</h3>
          <p>Track your crypto investments in real-time.</p>
        </div>
        <div class="feature-item">
          <h3>Profit/Loss Calculation</h3>
          <p>Automatically calculate your profits and losses.</p>
        </div>
        <div class="feature-item">
          <h3>Market Data Integration</h3>
          <p>Seamlessly update prices using our API.</p>
        </div>
      </div>
    </section>

    <!-- About Section -->
    <section
      id="about"
      class="about"
    >
      <h2>About Coin Guild</h2>
      <p>
        Coin Guild offers a powerful Excel-based tool for managing your cryptocurrency investments. 
        Perfect for beginners and experienced traders alike, our tool allows you to stay organized, track
        performance, and make data-driven decisions.
      </p>
    </section>

    <!-- Contact Section -->
    <section
      id="contact"
      class="contact"
    >
      <h2>Contact Us</h2>
      <p>Got questions? Reach out to us via email or follow us on social media.</p>
      <a
        href="mailto:support@coinguild.info"
        class="btn-secondary"
      >Email Us</a>
    </section>

    <!-- Footer Section -->
    <footer>
      <p>&copy; 2024 Coin Guild. All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
import HeaderComponent from './HeaderComponent.vue';
export default {
  name: 'CoinGuild',
  
  components: {
    HeaderComponent
  },
};
</script>

<style scoped>

/* Hero Section */
.hero {
  text-align: center;
  /*background: linear-gradient(to right, #d1f7e0,  #4fdab5); */
  background: linear-gradient(30deg, #d1f7e0, #4fdab5); 


  background: linear-gradient(10deg, #d1f7e0 20%, #4fdab5 70%);
  /*background: linear-gradient(to right, #4fdab5, #ffb84d);  Dégradé de vert à jaune */
  color: #ffffff;
  padding: 5rem 2rem;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.hero p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.hero .btn-primary {
  padding: 1rem 2rem;
  background-color: #ffffff;
  color: #4fdab5; /* Texte vert clair */
  text-decoration: none;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.hero .btn-primary:hover {
  background-color: #f4f4f4; /* Fond clair au hover */
}

/* Features Section */
.features {
  text-align: center;
  padding: 4rem 2rem;
  background-color: #ffffff; /* Fond blanc */
  color: #13352b;
}

.features h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  
}

.features .features-list {
  display: flex;
  justify-content: center;
  gap: 3rem;
}

.features .feature-item {
  max-width: 300px;
}

.features .feature-item h3 {
  font-size: 1.8rem;
  color: #4fdab5; /* Vert clair pour les titres */
}

.features .feature-item p {
  font-size: 1rem;
  color: #555;
}

/* About Section */
.about {
  padding: 4rem 2rem;
  text-align: center;
  background-color: #f9f9f9; /* Fond très clair */
  color: #13352b;
}

.about h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.about p {
  font-size: 1.2rem;
  color: #555;
  max-width: 800px;
  margin: 0 auto;
}

/* Contact Section */
.contact {
  background-color: #ffffff; /* Fond blanc */
  color: #333;
  padding: 4rem 2rem;
  text-align: center;
}

.contact h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.contact p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.contact .btn-secondary {
  padding: 1rem 2rem;
  background-color: #4fdab5; /* Vert clair pour le bouton */
  color: #ffffff; /* Texte blanc */
  text-decoration: none;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.contact .btn-secondary:hover {
  background-color: #ffb84d; /* Jaune doux au hover */
}

/* Footer */
footer {
  text-align: center;
  padding: 2rem;
  background-color: #ffffff; /* Fond blanc */
  color: #333;
}

footer p {
  font-size: 1rem;
}

</style>
