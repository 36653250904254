
import { authService } from './AuthService';

const API_URL = `${process.env.VUE_APP_API_URL}/users/api`;

export const apiService = {
    /**
     * Récupère la liste des APIs de l'utilisateur
     */
     buildHeaders(){
        const token = authService.getToken();
        const login = authService.getLogin();
        const headers={
            'Content-Type': 'application/json'
        };
        if(token){
            headers['Authorization']=`Bearer ${token}`;
            headers['COIN_GUILD_USER']=`${login}`;
        }
        console.log('headers',headers)
        return headers
    },
    async getApis() {
        try {

            const headers=this.buildHeaders();
            
            const response = await fetch(API_URL,{ headers: headers});
            if (!response.ok) {
                throw new Error('Failed get data');
            }
            const data= await response.json();
            if (data.error) {
                throw new Error(data.message);
            }
            return data;
            
            } catch (error) {
            console.error("Error fetching APIs:", error);
            throw error;
        }
    },

    /**
     * Crée une nouvelle API
     * @param {Object} apiData - Les données de l'API à créer
     * @param {string} apiData.name - Le nom de l'API
     * @param {string} apiData.usage - L'usage prévu de l'API
     * @param {string} apiData.expiration - La durée de validité de l'API
     */
    async createApi(apiData) {
        try {
            const headers=this.buildHeaders();
            const login = authService.getLogin();
            apiData.emailHashed=login;
            const response = await fetch(API_URL,{ 
                method: 'POST',
                headers: headers,
                body: JSON.stringify(apiData)
            });
            const data= await response.json();
            if (!response.ok) {
                console.error('response',response,data)
                throw new Error('Failed get data');
            }
            
            if (data.error) {
                console.error('data.error',data.error)
                throw new Error(data.message);
            }
            return data
        } catch (error) {
            console.error("Error creating API:", error);
            throw error;
        }
    },

    /**
     * Supprime une API existante
     * @param {string} apiId - L'identifiant de l'API à supprimer
     */
    async deleteApi(apiId) {
        try {
            const headers=this.buildHeaders();
            const response = await fetch(`${API_URL}/${apiId}`,{ 
                method: 'DELETE',
                headers: headers
            });
            if (!response.ok) {
                console.error("response:", response);
                throw new Error('Failed get data');
            }
            const data= await response.json();
            if (data.error) {
                console.error("data error:", data);
                throw new Error(data.message);
            }
            return data
        } catch (error) {
            console.error("Error deleting API:", error);
            throw error; 
        }
    }
};