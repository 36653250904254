<template>
  <div>
    <HeaderComponent />
    <section>
      <div class="download-container">
        <h1 class="text-2xl font-bold mb-6">Download CoinGuild</h1>
        
        <!-- Version selection -->
        <div class="version-selector mb-8">
          <h2 class="text-xl mb-4">Available Versions</h2>
          <div class="versions-grid">
            <div 
              v-for="version in versions" 
              :key="version.id"
              class="version-card"
              :class="{ 'selected': selectedVersion === version }"
              @click="selectVersion(version)"
            >
              <div class="version-header">
                <h3 class="version-title">Version {{ version.number }}</h3>
                <span v-if="version.isLatest" class="latest-badge">Latest</span>
              </div>
              <div class="version-details">
                <p class="release-date">Released: {{ formatDate(version.releaseDate) }}</p>
                <p class="file-size">Size: {{ formatSize(version.size) }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Download section -->
        <div class="download-section">
          <div v-if="selectedVersion" class="selected-version-info">
            <h3 class="text-lg mb-2">Ready to Download</h3>
            <p class="mb-4">Version {{ selectedVersion.number }} - {{ formatDate(selectedVersion.releaseDate) }}</p>
            
            <div class="changelog mb-4">
              <h4 class="font-bold">Changelog:</h4>
              <ul class="list-disc pl-5">
                <li v-for="(change, index) in selectedVersion.changelog" :key="index">
                  {{ change }}
                </li>
              </ul>
            </div>

            <button 
              @click="handleDownload"
              class="download-button"
              :disabled="downloading"
            >
              <span v-if="!downloading">Download Now</span>
              <span v-else>Downloading...</span>
            </button>
          </div>
          
          <div v-else class="no-selection">
            Please select a version to download
          </div>
        </div>

        <InfoBox 
          v-if="showInfoBox" 
          :title="infoBoxTitle" 
          :message="infoBoxMessage" 
          @close="handleCloseBox"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from 'vue'
import HeaderComponent from './HeaderComponent.vue'
import InfoBox from './InfoBox.vue'
import { userService } from '../services/UserService'

export default {
  name: 'DownloadPage',
  components: {
    HeaderComponent,
    InfoBox
  },

  setup() {
    const selectedVersion = ref(null)
    const downloading = ref(false)
    const showInfoBox = ref(false)
    const infoBoxTitle = ref('')
    const infoBoxMessage = ref('')

    // Mock versions data - À remplacer par les vraies données
    const versions = ref([
    {
        id: 1,
        number: '1.13.4',
        releaseDate: '2024-08-12',
        size: 446000, // 16MB
        isLatest: true,
        filename: 'suiviCryptoCoinGuild-1.13.4.starter.xlsx',
        changelog: [
          'Added goals',
          'Bug fixes and stability improvements'
        ]
      },
      {
        id: 2,
        number: '1.14.0',
        releaseDate: '2024-09-12',
        size: 446000, // 16MB
        isLatest: false,
        filename: 'suiviCryptoCoinGuild-1.14.0.starter.xlsx',
        changelog: [
          'add call Kucoin',
          'add key api managment',
          'add login sheet'
        ]
      }
    ])

    const formatDate = (date) => {
      return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    }

    const formatSize = (bytes) => {
      const sizes = ['Bytes', 'KB', 'MB', 'GB']
      if (bytes === 0) return '0 Byte'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
    }

    const selectVersion = (version) => {
      selectedVersion.value = version
    }

    const handleCloseBox = () => {
      showInfoBox.value = false
    }

    const handleDownload = async () => {
      if (!selectedVersion.value || downloading.value) return

      downloading.value = true
      
      try {
        // Enregistrer le téléchargement
        await userService.trackDownload({
          version: selectedVersion.value.number,
          timestamp: new Date().toISOString()
        })

        // Déclencher le téléchargement
        const downloadUrl = `/downloads/${selectedVersion.value.filename}`
        const link = document.createElement('a')
        link.href = downloadUrl
        link.download = selectedVersion.value.filename
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        infoBoxTitle.value = 'Success'
        infoBoxMessage.value = 'Your download has started!'
        showInfoBox.value = true
      } catch (error) {
        infoBoxTitle.value = 'Error'
        infoBoxMessage.value = 'Failed to start download. Please try again later.'
        showInfoBox.value = true
      } finally {
        downloading.value = false
      }
    }

    return {
      versions,
      selectedVersion,
      downloading,
      showInfoBox,
      infoBoxTitle,
      infoBoxMessage,
      formatDate,
      formatSize,
      selectVersion,
      handleDownload,
      handleCloseBox
    }
  }
}
</script>

<style scoped>

section {
  margin-top: 50px;
  text-align: center;
  background-color: var(--background-color);
  color: var(--text-color);
}
.download-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.versions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.version-card {
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: white;
}

.version-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.version-card.selected {
  border-color: var(--primary-color);
  background-color: rgba(79, 218, 181, 0.1);
}

.version-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.latest-badge {
  background-color: var(--primary-color);
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
}

.version-details {
  font-size: 0.875rem;
  color: #666;
}

.download-button {
  background-color: var(--primary-color);
  color: white;
  padding: 0.75rem 2rem;
  border-radius: 0.5rem;
  font-weight: bold;
  transition: background-color 0.2s ease;
}

.download-button:hover:not(:disabled) {
  background-color: var(--button-color);
}

.download-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.changelog {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 0.5rem;
  
  text-align: left;
}

.no-selection {
  text-align: center;
  color: #666;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 0.5rem;
}
</style>