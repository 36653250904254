<template>
  <header>
    <div class="logo">
      <img
        src="@/assets/coinguild.png"
        alt="Coin Guild Logo"
      >
    </div>
    <nav>
      <MenuBar />
    </nav>
  </header>
</template>
  
<script>
  import MenuBar from './MenuBar.vue';
  
  export default {
    name: 'HeaderComponent',
    components: {
      MenuBar,
    },
  };
</script>
  
<style scoped>
  /* Styles du Header */
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fcfef8; /* Gris foncé */
    color: #fff;
    position: sticky;
    top: 0;
    z-index: 100;
    padding: 1rem 2rem;
  }
  
  header .logo img {
    height: 140px;
  }
  
  header nav ul {
    list-style: none;
    display: flex;
    gap: 2rem;
  }
  
  header nav ul li a {
    text-decoration: none;
    color: rgb(189, 134, 46); /* Couleur 2 */
    font-weight: bold;
    transition: color 0.3s;
  }
  
  header nav ul li a:hover {
    color: rgb(100, 140, 169); /* Couleur 3 */
  }
  </style>
  