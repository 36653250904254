<template>
  AAA
  <div>
    <HeaderComponent />
    <section class="validate-page">
      <!-- Afficher la boîte de message d'information (InfoBox) -->
      <InfoBox 
        v-if="showInfoBox" 
        :title="infoBoxTitle" 
        :message="infoBoxMessage" 
        @close="handleCloseBox" 
      />
    

    
      <!-- Validation Popup de traitement -->
      <div
        v-if="showProcessingPopup"
        class="popup"
      >
        <div class="popup-content">
          <h1>Validating...</h1>
          <p>Please wait while we confirm your account.</p>
        </div>
      </div>
    
    </section>
  </div>
</template>
  
  <script>
  import HeaderComponent from './HeaderComponent.vue';
  import InfoBox from '@/components/InfoBox.vue'; // Import InfoBox component
  import  { userService } from '../services/UserService'; // Assuming userService is correctly implemented
  
  export default {
    components: {
      InfoBox,HeaderComponent
    },
    data() {
      return {
        showInfoBox: false,      // Controls InfoBox visibility
        infoBoxTitle: '',        // Title for the InfoBox
        infoBoxMessage: '',      // Message for the InfoBox
  
        showSuccessPopup: false, // Controls Success Popup visibility
        showProcessingPopup: false, // Controls Processing Popup visibility
        showErrorPopup: false, // Controls Error Popup visibility
      };
    },
    mounted() {
      // Lancer la validation lorsque la page est montée
      this.validateToken();
      console.log('ValidatePage mounted');
    },
    
    methods: {
      // Fonction pour valider le token
      validateToken() {
        const token = this.$route.query.token;
  
        // Affichage du popup de traitement pendant la validation
        this.showProcessingPopup = true;
        // eslint-disable-next-line no-debugger
        console.log('token',token)
        // Appel à l'API de validation du token
        userService.validate(token)
          .then((response) => {
            this.showProcessingPopup = false; // Cacher le popup de traitement
  
            if (response.success) {
              // Si la validation réussit
              this.infoBoxTitle = 'Verification Email Sent';
              this.infoBoxMessage = 'Your email has been successfully validated. We are redirecting you to the download page.';
              this.showInfoBox = true;
            } else {
              // Si la validation échoue
              this.infoBoxTitle = 'Validation Failed';
              this.infoBoxMessage = `The validation failed. Please try again later. ${response?.data?.message}`;
              this.showInfoBox = true;
            }
          })
          .catch((error) => {
            // Gérer l'erreur si l'API échoue
            console.error("response",error);
            this.showProcessingPopup = false; // Cacher le popup de traitement
            this.infoBoxTitle = 'Error';
            if(error.response && error.response.data && error.response.data.message){
                if(error.response.data.code=="AUTH002003"){
                    this.infoBoxMessage = `An error occurred while validating your account. ${error.response.data.message}`;
                }else{
                    this.infoBoxMessage = `An error occurred while validating your account. Please try again later. error.msg=${error.message}`;
                }
            }else{
                this.infoBoxMessage = `An error occurred while validating your account. Please try again later. error.msg=${error.message}`;
            }
           
            
            this.showInfoBox = true;

          });
      },
  
      goToDownloadPage() {
        this.$router.push("/download"); // Redirige vers la page de téléchargement
      },
  
      handleCloseBox() {
        this.showInfoBox = false; // Fermer la boîte de message InfoBox
      },
  
      handleErrorClose() {
        this.showErrorPopup = false; // Fermer le popup d'erreur
      },
    },
  };
</script>
  
<style scoped>
  .validate-page {
    text-align: center;
    margin-top: 50px;
  }
  
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 20px;
    border-radius: 10px;
    z-index: 1000;
  }
  
  .popup-content {
    text-align: center;
  }
  
  .popup button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4fdab5;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .popup button:hover {
    background-color: #3eb897;
  }
  </style>
  